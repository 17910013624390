import React from 'react';
import * as THREE from 'three';
import { useThree, useFrame } from 'react-three-fiber';
import { useStore } from './useStore';

export function CoordinateTransmitter() {
  const { mouse, camera } = useThree();
  const { x, y, setX, setY } = useStore();

  useFrame(() => {
    const vector = new THREE.Vector3(mouse.x, mouse.y, -1).unproject(camera);
    setX(vector.x.toFixed(3));
    setY(vector.y.toFixed(3));
  });
  return <></>;
}
