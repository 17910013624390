import React from 'react';
import { extend, useThree, useResource, 
  useFrame } from 'react-three-fiber'
import * as THREE from 'three'

import CameraControls from 'camera-controls'
CameraControls.install({ THREE })
extend({ CameraControls })

export default function CamControls() {
  const [ref, object] = useResource()

  const { camera, gl } = useThree()
  useFrame((state, delta) => {
    if (ref.current) {
      ref.current.mouseButtons.right = CameraControls.ACTION.TRUCK;
      ref.current.mouseButtons.left = null;
      ref.current.update(delta);
    }

  })
  return <cameraControls ref={ref} args={[camera, gl.domElement]} />
}