import * as THREE from 'three';
import { degrees_to_radians } from './math_functions';


export const calculateIntersection = (louver1, louver2, yOffset) => {
  const angle1 = degrees_to_radians(90 - louver1.angle);

  // Midpoint of the top edge before rotation
  const midpointX = 0;
  const midpointY = louver1.louverSlotHeight / 2;

  // Rotated midpoint of louver1
  const rotatedMidpointX = midpointX * Math.cos(angle1) - midpointY * Math.sin(angle1);
  const rotatedMidpointY = midpointX * Math.sin(angle1) + midpointY * Math.cos(angle1);

  // Calculate the intersection point on louver2
  const slope1 = -1 / Math.tan(angle1);
  // const intersectionY = louver2.yOffset - louver2.louverSlotHeight / 2
  // const intersectionX = rotatedMidpointX + (intersectionY - rotatedMidpointY) / slope1
  const ratio = louver1.louverSlotHeight / yOffset;
  //console.log(ratio)
  // Distance along the normal direction
  let distance = yOffset * Math.cos(angle1) - louver1.louverSlotHeight;
  //distance = distance * ratio
  //console.log('web is ', distance - louver1.louverSlotHeight)
  // Calculate intersection point
  const intersectionX = rotatedMidpointX - distance * Math.sin(angle1);
  const intersectionY = rotatedMidpointY + distance * Math.cos(angle1);

  return {
    intersection: new THREE.Vector3(intersectionX, intersectionY, 0),
    web_thickness: distance
  };
};
