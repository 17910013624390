import React, { useMemo, useCallback, forwardRef } from 'react';
import * as THREE from 'three';
import { degrees_to_radians } from './math_functions';

// import { useHelper } from "drei"
// import { BoxHelper } from 'three'

export const Louver = forwardRef((props, ref) => {
  const { louverSlotLength, louverSlotHeight, yOffset } = props;

  //useHelper(ref, BoxHelper, "#272740")

  const points = useMemo(() => {
    const path = new THREE.Path();
    const l = louverSlotLength;
    const w = louverSlotHeight;
    const cl1 = 0 - louverSlotLength / 2 + w / 2;
    const cl2 = 0 + louverSlotLength / 2 - w / 2;
    const arc1StartX = cl1;
    const arc2StartX = cl2;
    path.moveTo(arc1StartX, w / 2);
    path.absarc(arc1StartX, 0, w / 2, degrees_to_radians(90), degrees_to_radians(270), false);
    path.lineTo(arc2StartX, -w / 2);
    path.absarc(arc2StartX, 0, w / 2, degrees_to_radians(270), degrees_to_radians(90), false);
    path.lineTo(arc1StartX, w / 2);

    const points = path.getPoints();
    return points;
  }, [louverSlotLength, louverSlotHeight]);

  const onUpdate = useCallback(
    (self) => {
      self.setFromPoints(points);
      self.rotateZ(degrees_to_radians(90 - props.angle));
      //console.log(self);
      self.computeBoundingBox();
      let size = new THREE.Vector3();
      props.onUpdate({
        boundingBox : self.boundingBox,
        size: self.boundingBox.getSize(size)
      });
    },
    [points, props.angle]
  );

  const onLineUpdate = useCallback(
    (self) => {
      self.geometry.computeBoundingBox();
      let size = new THREE.Vector3();
      // props.onUpdate({
      //   miny: self.geometry.boundingBox.min.y,
      //   size: self.geometry.boundingBox.getSize(size)
      // });
      //console.log('louver1 line props',self);
    },
    [points, props.angle, ref]
  );



  return (
    <>
      <line position={[0, props.yOffset, 0]} ref={ref} onUpdate={onLineUpdate}>
        <bufferGeometry attach="geometry" onUpdate={onUpdate} />
        <lineBasicMaterial attach="material" color={'#9c88ff'} linewidth={10} linecap={'round'} linejoin={'round'} />
      </line>
    </>
  );
});
