/**
 * A React component that updates the document title to the provided `title` prop.
 *
 * @param {object} props - The component props.
 * @param {string} props.title - The title to set for the document.
 * @returns {React.ReactElement} - An empty React element.
 */
import React from 'react';
import { useEffect } from 'react';

export function Titler(props) {
  useEffect(() => {
    document.title = props.title;
  }, []);

  return (
    <></>
  );
}
