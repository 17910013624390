// @see https://threejs.org/docs/#api/en/objects/Line
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { extend, useResource } from 'react-three-fiber'
// import { DragControls } from 'three/examples/jsm/controls/DragControls'
import { PerspectiveCamera, useHelper } from 'drei'
//import { BoxHelper } from 'three'
import './louvercalc/styles.css'
import { App } from './louvercalc/App'

ReactDOM.render(<App />, document.getElementById('root'))
