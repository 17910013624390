import React, { useMemo, useCallback, forwardRef } from 'react';
import * as THREE from 'three';

export const VisibleLine = forwardRef((props, ref) => {
  const points = useMemo(() => {
    const path = new THREE.Path();
    path.moveTo(props.startX, props.startY);
    path.lineTo(props.endX, props.endY);
    const points = path.getPoints();
    return points;
  });
  const onUpdate = useCallback(
    (self) => {
      self.setFromPoints(points);
    },
    [points]
  );
  return (
    <>
      <line position={[0, 0, 0]} ref={ref}>
        <bufferGeometry attach="geometry" onUpdate={onUpdate} />
        <lineBasicMaterial attach="material" color={'black'} linewidth={10} linecap={'round'} linejoin={'round'} />
      </line>
      {/* {object} */}
      {/* <dragControls args={[[object], camera, gl.domElement]} /> */}
    </>
  );
});
