import create from 'zustand';

// extend({ DragControls })
// function radians_to_degrees(radians) {
//   var pi = Math.PI
//   return radians * (180 / pi)
// }

export const useStore = create((set) => ({
  x: 0,
  y: 0,
  cameraZoom: 144,
  setX: (val) => {
    set((state) => ({ x: val / 1 }));
  },
  setY: (val) => {
    set((state) => ({ y: val / 1 }));
  },
  setCameraZoom: (val) => {
    set((state) => ({ cameraZoom: val }));
  }
}));
