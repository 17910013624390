import React, { useMemo, useRef } from 'react';
import * as THREE from 'three';
import { Canvas } from 'react-three-fiber';
import { OrthographicCamera } from 'drei';
import { degrees_to_radians } from './math_functions';
import { VisibleLine } from './VisibleLine';
import { Louver } from './Louver';
import { StyledSlider } from './StyledSlider';
import { useStore } from './useStore';
import { CoordinateTransmitter } from './CoordinateTransmitter';
import { calculateIntersection } from './calculateIntersection';
import CamControls from './CamControls'
import { Titler } from './Titler';

export const App = (props) => {
  const cam = useRef();
  const louver1Ref = useRef();
  const louver2Ref = useRef();

  const intersectionRef = useRef();

  const [stileThickness, setStileThickness] = React.useState(1.125);
  const [louverWidth, setLouverWidth] = React.useState(1.3125);
  const [louverHeight, setLouverHeight] = React.useState(0.25);
  const [louverAngle, setLouverAngle] = React.useState(22);

  // Edge clearance: The web on each edge of the stile
  // this really should be a calculated property
  const [edgeClearance, setEdgeClearance] = React.useState();

  // The camera zoom value for use with the slider.
  // With camera-controls, this may not be relevant anymore
  const [cameraZoom, setCameraZoom] = React.useState(41);

  // Actual offset between louvers (in y axis)
  const [yOffset, setYOffset] = React.useState(0);

  // Height of louver 1 (thickness)
  const [l1Height, setL1Height] = React.useState(0);

  // Minimum Y point of louver 2 (not sure if we'll use this now)
  const [l2MinY, setL2MinY] = React.useState(0);

  // Minimum offset between louvers (center-to-center in y axis)
  const [minOffset, setMinOffset] = React.useState(0);

  // Shutter build parameters (sort-of)
  const [spaceToFill, setSpaceToFill] = React.useState(24);
  const [yStart, setYStart] = React.useState(6); // where the louvers start to fill
  const [yClearance, setYClearance] = React.useState(0.0); // how far to stay inside the louver area

  const { x, y } = useStore();

  // These are here so we can add a clearance amount if so desired 
  // That way, the slots for the louvers can be made a little over-sized.
  const louverSlotLength = parseFloat(louverWidth) + 0;
  const louverSlotHeight = parseFloat(louverHeight) + 0;

  const louver1Props = {
    louverSlotLength,
    louverSlotHeight,
    yOffset: 0,
    angle: louverAngle
  };

  const louver2Props = {
    louverSlotLength,
    louverSlotHeight,
    yOffset,
    angle: louverAngle
  };

  // web_thickness: The thickness of the web of material between each louver
  const { intersection, web_thickness } = useMemo(
    () => calculateIntersection(louver1Props, louver2Props, yOffset),
    [louver1Props, louver2Props, yOffset]
  );

  const handleSliderChange = (event, newValue) => {
    setLouverAngle(newValue);
  };

  const handleSlider2Change = (event, newValue) => {
    setCameraZoom(newValue);
  };

  const handleYOffsetSliderChange = (event, newValue) => {
    setYOffset(newValue);
  };

  const onLouver1Update = (param) => {
    //setL1Height(param.size.y)
    console.log('param',param);
    setL1Height(param.size.y);
    setEdgeClearance((stileThickness - param.size.x) / 2)
  };

  const onLouver2Update = (param) => {
    setL2MinY(param.miny);
  };

  // put everything inside a scene
  //useHelper(louver1Ref, THREE.BoxHelper, '#272740')


  // const yShift = yStart + yClearance + (.5 * l1Height)
  // console.log('yShift', yShift)

  return (
    <div style={{ display: 'flex', width: '100%', height: '100vh' }}>
      <Titler title="Louver Calculator" />
      <div style={{ flex: '0 0 70%', height: '100%' }}>
        <Canvas
          orthographic={true}
          camera={{
            left: -30,
            right: 30,
            top: 0,
            bottom: -7,
            near: 1,
            far: 10,
            zoom: 130
          }}>
          <OrthographicCamera makeDefault ref={cam} position={[0, 0, 10]} zoom={cameraZoom} />
          <pointLight position={[0, 0, 3]} color="#f7f3ce" intensity={0.1} />
          <ambientLight color="#fff" intensity={0.85} />
          <CamControls></CamControls>
          <axesHelper args={[1.25]} />
          <VisibleLine startX={0 - 0.5 * stileThickness} startY={-10} endX={0 - 0.5 * stileThickness} endY={48}></VisibleLine>
          <VisibleLine startX={0 + 0.5 * stileThickness} startY={-10} endX={0 + 0.5 * stileThickness} endY={48}></VisibleLine>
          <Louver
            yOffset={0}
            angle={louverAngle}
            onUpdate={onLouver1Update}
            louverSlotLength={louverSlotLength}
            louverSlotHeight={louverSlotHeight}
            ref={louver1Ref} />
          {/* <boxHelper args={[louver1Ref]} /> */}
          <Louver
            yOffset={yOffset}
            angle={louverAngle}
            onUpdate={onLouver2Update}
            louverSlotLength={louverSlotLength}
            louverSlotHeight={louverSlotHeight}
            ref={louver2Ref} />
          <Louver
            yOffset={yOffset * 2}
            angle={louverAngle}
            onUpdate={onLouver2Update}
            louverSlotLength={louverSlotLength}
            louverSlotHeight={louverSlotHeight} />
          <Louver
            yOffset={yOffset * 3}
            angle={louverAngle}
            onUpdate={onLouver2Update}
            louverSlotLength={louverSlotLength}
            louverSlotHeight={louverSlotHeight} />
          <Louver
            yOffset={yOffset * 4}
            angle={louverAngle}
            onUpdate={onLouver2Update}
            louverSlotLength={louverSlotLength}
            louverSlotHeight={louverSlotHeight} />
          <Louver
            yOffset={yOffset * 5}
            angle={louverAngle}
            onUpdate={onLouver2Update}
            louverSlotLength={louverSlotLength}
            louverSlotHeight={louverSlotHeight} />
          <Louver
            yOffset={yOffset * 6}
            angle={louverAngle}
            onUpdate={onLouver2Update}
            louverSlotLength={louverSlotLength}
            louverSlotHeight={louverSlotHeight} />
          {yOffset > 0 && (
            <line ref={intersectionRef}>
              <bufferGeometry
                attach="geometry"
                onUpdate={(self) => {
                  const start = new THREE.Vector3(0, louverHeight / 2, 0).applyAxisAngle(
                    new THREE.Vector3(0, 0, 1),
                    degrees_to_radians(90 - louverAngle)
                  );
                  const points = [start, intersection];
                  self.setFromPoints(points);
                }} />
              <lineBasicMaterial attach="material" color={'#000'} linewidth={1} />
            </line>
          )}
          <CoordinateTransmitter />
        </Canvas>
      </div>
      <div style={{ backgroundColor: 'silver', padding: '10px' }}>
        <div>Stile Thickness: </div>
        <input value={stileThickness.toString()} onChange={(e) => setStileThickness(e.target.value)} />
        <hr />
        <div>Length: </div>
        <input value={louverWidth.toString()} onChange={(e) => setLouverWidth(e.target.value)} />
        <hr />
        <div>Thickness: </div>
        <input value={louverHeight} onChange={(e) => setLouverHeight(e.target.value)} />
        <hr />
        <StyledSlider
          value={typeof louverAngle === 'number' ? louverAngle : 0}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          min={0}
          step={0.1}
          max={90} />
        <span>Angle: {louverAngle}</span>
        <hr />
        
        {/* <StyledSlider value={cameraZoom} onChange={handleSlider2Change} aria-labelledby="input-slider" max={200} /> */}
        {/* <div>
          Camera Zoom: <span>{cameraZoom}</span>
        </div>
        <hr /> */}
        <StyledSlider
          value={yOffset}
          onChange={handleYOffsetSliderChange}
          aria-labelledby="input-slider"
          min={minOffset}
          step={0.001}
          max={2} />
        <div>
          Y Offset: <span>{yOffset}</span>
        </div>
        <hr />
        <div>
          X: {x.toFixed(3)}, Y: {y.toFixed(3)}
        </div>
        <hr />
        <div>Web Thickness: {web_thickness?web_thickness.toFixed(4):-99}</div>
        <hr />
        <div>Edge Clearance: {edgeClearance?edgeClearance.toFixed(4):-99}</div>
      </div>
    </div>
  );
};
